<template>
  <div class="text-center relative">
      <v-card class="text-center" :flat="vertical">
        <v-card-actions v-if="!vertical">
          <v-spacer></v-spacer>
          <v-btn
            v-if="$hasRole('ROLE_DELETOR') && sheet && deleteableRecords.length"
            color="success"
            @click="startDeletion()"
          >{{ $t('delete') }}</v-btn>
          <v-btn
            v-if="sheet"
            color="error"
            @click="sheet = false"
          >{{ $t('cancel') }}</v-btn>
        </v-card-actions>
        <v-toolbar
            v-else
            tile
            color="primary"
            dark
            flat
            :height="toolbarHeight"
        >
          <v-toolbar-title>{{ $tc('vvtList.more.actions.delete.title', records.length) }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom color="success">
              <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      ripple
                      tile
                      class="ma-0 mr-3"
                      color="success"
                      @click="startDeletion()"
                      :disabled="!$hasRole('ROLE_DELETOR') || !sheet || !deleteableRecords.length"
                  >
                      <v-icon>mdi-minus-circle-multiple-outline</v-icon>
                      <span v-if="$vuetify.breakpoint.mdAndUp" v-t="'delete'" class="pl-3"></span>
                  </v-btn>
              </template>
              <span>{{ $tc('vvtList.more.actions.delete.title', records.length) }}</span>
          </v-tooltip>
          <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="white"
                      outlined
                      fab
                      small
                      :elevation="2"
                      class="ma-2"
                      @click="sheet = false"
                  >
                      <v-icon color="white">mdi-close</v-icon>
                  </v-btn>
              </template>
              <span v-t="'cancel'"></span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text class="pa-3">
          <v-container fluid class="pa-0">
            <template v-if="hasUndeleteable">
                <v-alert
                    type="warning"
                    outlined
                    class="mb-3"
                    border="left">
                    {{ $t('vvtList.more.actions.delete.statusInfo')  }}
                </v-alert>
            </template>
            <v-row v-if="deleteableRecords.length">
              <v-col v-if="!hideRecords" cols="12" :lg="vertical ? 12 : 5">
                <SimpleRecordList :table="vertical" :records="deleteableRecords" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!sheet && deleteableRecords.length"
            color="success"
            @click="startDeletion()"
          >{{ $t('duplicate') }}</v-btn>
        </v-card-actions>
    </v-card>
    <v-overlay
      absolute
      :color="color"
      :value="disabled"
    >
      <slot name="overlayContent"></slot>
    </v-overlay>
  </div>
</template>

<script>
import http from '@/utils/axios';
const SimpleRecordList = () => import(/* webpackChunkName: "SimpleRecordList" */ '@/components/vvt/List/SimpleRecordList.vue');
export default {
  components: {
    SimpleRecordList
  },
  props: {
    deletionSheet: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideRecords: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    },
    records: {
      type: Array,
      default: () => []
    },
    color: {
      type: String,
      default: null
    },
    toolbarHeight: {
        type: Number,
        default: 64
    }
  },
  i18n: {
      messages: {
          en: require('@/locales/vvt/List/en.json'),
          de: require('@/locales/vvt/List/de.json'),
      },
  },
  computed: {
    sheet: {
      get () {
        return this.deletionSheet;
      },
      set (val) {
        this.$emit('setDeletionSheet', val);
      }
    },
    deleteableRecords() {
        return this.records.filter(x => ['draft','archived'].includes(x.status) && !x.authorityReportRelevance);
    },
    hasUndeleteable() {
        return this.records.find(x => ((x.status !== 'draft' && x.status !== 'archived') || x.authorityReportRelevance));
    }
  },
  methods: {
      startDeletion () {
        this.$wait.start('pageLoader');
        const sourceRecords = this.deleteableRecords.map(x => x.id);
        http.post('/api/processingActivity/deleteMultiple', JSON.stringify({sourceRecords}))
            .then(response => {
                if (!response.data.error) {
                  this.$wait.end('pageLoader');
                  this.sheet = false;
                  this.$emit('multipleActionSuccess', 'vvtList.more.actions.delete.successMessage');
                } else {
                  this.$wait.end('pageLoader');
                }
            })
            .catch(() => {
                this.$wait.end('pageLoader');
            })
      }
  }
}
</script>
